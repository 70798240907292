.max-width{
    max-width: 100%;
}

.or-div{
    width: 100%; 
    height: 20px; 
    border-bottom: 1px solid #efefef; 
    text-align: center
}

.or-span{
    background-color: #ffffff; 
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    width: 60px;
    
    
}

.logo{
    margin-left: 100px;
}